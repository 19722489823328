.sphere-chain-selector__list {
  margin-top: 0.75rem;
}

.sphere-chain-selector__list-item {
  padding: 0px 0.75rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  border-color: transparent;
  border-width: 2px;
  border-radius: 9999px;
  border-style: solid;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 0.5rem;
  height: 3.625rem;
  width: 100%;
}

.sphere-chain-selector__list-item:hover,
.sphere-chain-selector__list-item:focus {
  background-color: rgb(243 244 246);
}
